<template>
  <NuxtLink :to="previousRoute">
    <Button size="icon" variant="ghost">
      <ButtonIcon :icon="icon ? icon : mapIcon.close" />
    </Button>
  </NuxtLink>
</template>

<script setup lang="ts">
const props = defineProps<{ fallbackRoute: string; icon?: string }>();

const route = useRoute();

const previousRoute = computed(() => (route.query.from as string) ?? props.fallbackRoute);
</script>

<style scoped></style>
